import styled, { css } from 'styled-components'
import Stack, { StackItem } from '../layout/Stack'
import Bullets from './Bullets'
import PlusIcon from '../icons/PlusIcon'
import PartnerLogo from '../PartnerLogo'

function Additional( { additional } ) {
  const partnerLogo = additional.find( item => item.typeHandle === 'partnerLogo' )

  return (
    <StyledAdditional>
      <Stack $between={ 1 }>
        { additional.map( item => {
          if ( item.typeHandle === 'text' ) {
            return (
              <StyledText
                key={ item.id }
                dangerouslySetInnerHTML={ { __html: item.text } }
              />
            )
          }
        
          if ( item.typeHandle === 'textHighlight' ) {
            return (
              <StyledText
                key={ item.id }
                dangerouslySetInnerHTML={ { __html: item.text } }
                $highlight
              />
            )
          }
        
          if ( item.typeHandle === 'bullets' ) {
            return (
              <StackItem
                key={ item.id }
                $top={ 3 }
                $bottom={ 3 }
              >
                <Bullets
                  bullets={ item.bullets }
                />
              </StackItem>
            )
          }

          return null
        } ) }
      </Stack>
      { partnerLogo && (
        <StyledPartnerLogo
          key={ partnerLogo.id }
        >
          <PartnerLogo
            label={ partnerLogo.partnerLabel }
            logos={ partnerLogo.partnerLogo }
          />
        </StyledPartnerLogo>
      ) }
      <StyledPlus>
        <PlusIcon />
      </StyledPlus>
    </StyledAdditional>
  )
}

const StyledAdditional = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 0;
  }

  @media ( max-width: 650px ) {
    min-width: 100%;
    padding-top: 3rem !important;
  }
`

const StyledText = styled.div<{
  $highlight?: boolean,
}>`
  ${ p => p.theme.typo.semiBold };

  p {
    margin: 0;
  }

  ${ p => p.$highlight && css`
    padding: var( --stack-basis ) 1rem;
    background: ${ p.theme.colors.blue200 };
  ` }
`

const StyledPlus = styled.span`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate( -50%, -70% );
  display: flex;
  align-items: center;
  justify-content: center;
  width: 51px;
  height: 51px;
  border-radius: 100%;
  background: ${ p => p.theme.colors.realWhite };

  @media ( max-width: 650px ) {
    left: 50%;
    top: 0;
    transform: translate( -50%, -50% );
  }
`

const StyledPartnerLogo = styled.div`
  justify-self: flex-end;
`

export default Additional
